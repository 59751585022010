import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Auth } from "aws-amplify";
import _ from "lodash";

import { updateUserInfo, fetchPractices } from "../../../actions";

import Layout from "../../../components/layout";
import Container from "../../../common/components/container";
import { Dropdown } from "../../../components/common/dropdown";

const Index = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const practices = useSelector((state) => state.practices);
  const practiceList = practices;

  useEffect(() => {
    dispatch(fetchPractices());
  }, []);

  async function signOut() {
    try {
      await Auth.signOut();
      dispatch(updateUserInfo({ isAuthenticated: false }));
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  async function update_practice_details(value) {
    console.log("value");
    console.log(value);
    console.log("practices");
    console.log(practices);
    const name = _.find(practices, { id: value });
    console.log("name");
    console.log(name);
    if (name === undefined) {
      return;
    }
    try {
      let user = await Auth.currentAuthenticatedUser();
      console.log(user);
      console.log(typeof name.id);
      let result = await Auth.updateUserAttributes(user, {
        "custom:Practice": name.id,
      });
    } catch (error) {
      console.log(error);
    }

    console.log(name);
    dispatch(updateUserInfo({ practice: { id: value, name: name.name } }));
  }
  var practice_name = _.find(practiceList, { id: user.practice_id }) || {
    name: "",
  };
  return (
    <Layout>
      <Container>
        <div>Practice: {practice_name.name || ""}</div>
        <Dropdown list={practiceList} update={update_practice_details} />
        <button
          onClick={() => {
            signOut();
          }}
          type="button"
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Sign Out
        </button>
      </Container>
    </Layout>
  );
};

export default Index;
